<template>
    <v-btn x-large block rounded color="#00c73c" class="white--text" id="naver-login-button" :href="`https://nid.naver.com/oauth2.0/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}`" v-bind="$attrs">
        <slot default>네이버 계정으로 로그인</slot>
    </v-btn>
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" },
    },
    computed: {
        redirect_uri() {
            return `${window.location.protocol}//${window.location.hostname}/oauth/naver`;
        },
    },
};
</script>
