<template>
    <client-page class="wrap--login grey-f7">
        <v-container>
            <div class="tit-wrap tit-wrap--subtitle text-center">
                <small>LOGIN</small>
                <h2 class="tit">{{ $t("common.로그인") }}</h2>
            </div>

            <v-card rounded="xl" elevation="6" max-width="550" class="ma-auto pa-20 pa-lg-60">
                <div class="login-rowgroup">
                    <div class="login-row">
                        <v-text-field v-model="username" outlined hide-details placeholder="아이디 또는 이메일" type="text"></v-text-field>
                    </div>
                    <div class="login-row mt-10 mt-md-20">
                        <v-text-field v-model="password" @keydown.enter="login" outlined hide-details placeholder="비밀번호" type="password"></v-text-field>
                    </div>
                    <div class="login-row login-row--submit mt-16 mt-md-20">
                        <v-btn @click="login" block x-large rounded color="primary">{{ $t("common.로그인") }}</v-btn>
                    </div>
                    <div class="mt-20">
                        <!-- <v-checkbox v-model="remember" :value="true" :label="$t('common.아이디 저장')" hide-details></v-checkbox> -->
                        <div class="row-bar-list-wrap">
                            <v-row justify="center" class="row-bar-list font-size-14">
                                <v-col cols="auto">
                                    <v-btn x-small text color="grey-2" to="/login/find-id/" class="h-auto pa-0 v-btn--none-active-bg">{{ $t("common.아이디 찾기") }}</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small text color="grey-2" to="/login/find-password/" class="h-auto pa-0 v-btn--none-active-bg">{{ $t("common.비밀번호 찾기") }}</v-btn>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn x-small text color="grey-2" to="/join" class="h-auto pa-0 v-btn--none-active-bg">회원가입</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </div>
                <!-- 회원가입/소셜버튼 -->
                <div class="tit-wrap tit-wrap--centerline justify-center mt-30 mt-md-50">
                    <h2 class="tit">SNS 간편 로그인</h2>
                </div>
                <div class="login-rowgroup login-rowgroup--buttons">
                    <kakao-login client_id="a1fe28b93668f4053dd4728e8e445f98" class="mt-10"><i class="icon icon-kakao mr-8"></i> 카카오로 3초 만에 시작하기</kakao-login>
                    <!-- <naver-login client_id="" class="mt-10"><i class="icon icon-naver mr-8"></i>네이버로 3초 만에 시작하기</naver-login> -->
                </div>
            </v-card>
        </v-container>
        <div class="container">

        </div>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CryptoAES from "@/plugins/crypto-aes";
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";
import NaverLogin from "@/components/plugins/naver/naver-login.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        KakaoLogin,
        NaverLogin,
    },
    data() {
        return {
            username: "",
            password: "",

            remember: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function() {
            if (this.$cookies.get("username")) this.username = this.$cookies.get("username");
            // if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
        },
        async login() {
            try {
                await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

                if (this.remember) {
                    this.$cookies.set("username", this.username);
                    this.$cookies.set("password", CryptoAES.encrypt(this.password));
                }

                this.$router.push("/");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
    },
};
</script>
