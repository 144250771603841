var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "block": "",
      "rounded": "",
      "color": "#fee500",
      "id": "kakao-login-btn",
      "href": `https://kauth.kakao.com/oauth/authorize?client_id=${_vm.client_id}&redirect_uri=${_vm.redirect_uri}&response_type=code`
    }
  }, 'v-btn', _vm.$attrs, false), [_vm._t("default", function () {
    return [_vm._v("카카오 계정으로 로그인")];
  }, {
    "default": ""
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }