var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--login grey-f7"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--subtitle text-center"
  }, [_c('small', [_vm._v("LOGIN")]), _c('h2', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$t("common.로그인")))])]), _c('v-card', {
    staticClass: "ma-auto pa-20 pa-lg-60",
    attrs: {
      "rounded": "xl",
      "elevation": "6",
      "max-width": "550"
    }
  }, [_c('div', {
    staticClass: "login-rowgroup"
  }, [_c('div', {
    staticClass: "login-row"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "아이디 또는 이메일",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1), _c('div', {
    staticClass: "login-row mt-10 mt-md-20"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "비밀번호",
      "type": "password"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('div', {
    staticClass: "login-row login-row--submit mt-16 mt-md-20"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "x-large": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v(_vm._s(_vm.$t("common.로그인")))])], 1), _c('div', {
    staticClass: "mt-20"
  }, [_c('div', {
    staticClass: "row-bar-list-wrap"
  }, [_c('v-row', {
    staticClass: "row-bar-list font-size-14",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto pa-0 v-btn--none-active-bg",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-2",
      "to": "/login/find-id/"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.아이디 찾기")))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto pa-0 v-btn--none-active-bg",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-2",
      "to": "/login/find-password/"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.비밀번호 찾기")))])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto pa-0 v-btn--none-active-bg",
    attrs: {
      "x-small": "",
      "text": "",
      "color": "grey-2",
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1)], 1)], 1)])]), _c('div', {
    staticClass: "tit-wrap tit-wrap--centerline justify-center mt-30 mt-md-50"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("SNS 간편 로그인")])]), _c('div', {
    staticClass: "login-rowgroup login-rowgroup--buttons"
  }, [_c('kakao-login', {
    staticClass: "mt-10",
    attrs: {
      "client_id": "a1fe28b93668f4053dd4728e8e445f98"
    }
  }, [_c('i', {
    staticClass: "icon icon-kakao mr-8"
  }), _vm._v(" 카카오로 3초 만에 시작하기")])], 1)])], 1), _c('div', {
    staticClass: "container"
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }