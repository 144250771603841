var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--login grey-f7"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--subtitle text-center"
  }, [_c('small', [_vm._v("id search")]), _c('h2', {
    staticClass: "tit"
  }, [_vm._v("아이디 찾기")])]), _c('v-card', {
    staticClass: "ma-auto pa-20 pa-lg-60",
    attrs: {
      "rounded": "xl",
      "elevation": "6",
      "max-width": "550"
    }
  }, [_c('div', {
    staticClass: "login-rowgroup"
  }, [_c('div', {
    staticClass: "login-row login-row--button mt-0"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이메일",
      "type": "text"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "v-btn--input white--text",
    attrs: {
      "color": "grey-7"
    },
    on: {
      "click": _vm.certify
    }
  }, [_vm._v("인증번호전송")])], 1)], 1)], 1), _c('div', {
    staticClass: "login-row mt-16 mt-md-20"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "인증번호 입력",
      "type": "number"
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  })], 1), _c('div', {
    staticClass: "login-row login-row--submit mt-16 mt-md-20"
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.recovery
    }
  }, [_vm._v("확인")])], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }